import * as React from "react"
import "../components/layout.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"
// import "../../node_modules/bootstrap/dist/js/bootstrap.min.js"

import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import Chip  from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert'; 
import MailIcon from '@mui/icons-material/Mail';
import Box      from '@mui/material/Box';
import List     from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText   from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { LinearProgress } from "@mui/material";

//Icons
import {MenuBook, MenuBookSharp} from '@mui/icons-material';
import {Coffee, Code} from '@mui/icons-material';

//DevIcon
import '../../node_modules/devicon/devicon.min.css'

function createData(
    route,
    description,
    status,
) {
    return { route, description, status };
}

const rows = [
    createData('proj-ordersystem', 'Built with Android, ReactJS, Push Notifications, Star Printer / IPv4 & Bluetooth.  System cataloges restaurant food items imported from a json data file.  Client app sends food orders to server which prints to an industry restaurant Star printer', 'Active Development' ),
    createData('/articles', 'Various misc topics and short blogs', 'Active Development' ),
    createData('/portfolio', 'Projects and Project Presentations', 'Active Development'),
    createData('/photography', 'Art, Illustrations, Photography', 'Active Development'),
];


// styles
const pageStyles = {
    color: "#232129",
    padding: 96,
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 320,
}
const headingAccentStyles = {
    color: "#663399",
}
const paragraphStyles = {
    marginBottom: 48,

    fontSize: "1.25rem",
    borderRadius: 4,
}
const listStyles = {
    marginBottom: 96,
    paddingLeft: 0,
}
const listItemStyles = {
    fontWeight: 300,
    fontSize: 24,
    maxWidth: 560,
    marginBottom: 30,
}

const linkStyle = {
    color: "#8954A8",
    fontWeight: "bold",
    fontSize: 16,
    verticalAlign: "5%",
}

const docLinkStyle = {
    ...linkStyle,
    listStyleType: "none",
    marginBottom: 24,
}

const descriptionStyle = {
    color: "#232129",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 0,
    lineHeight: 1.25,
}

const docLink = {
    text: "Documentation",
    url: "https://www.gatsbyjs.com/docs/",
    color: "#8954A8",
}

const badgeStyle = {
    color: "#fff",
    backgroundColor: "#088413",
    border: "1px solid #088413",
    fontSize: 11,
    fontWeight: "bold",
    letterSpacing: 1,
    borderRadius: 4,
    padding: "4px 6px",
    display: "inline-block",
    position: "relative",
    top: -2,
    marginLeft: 10,
    lineHeight: 1,
}

// data
const links = [
    {
        text: "Tutorial",
        url: "https://www.gatsbyjs.com/docs/tutorial/",
        description:
        "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
        color: "#E95800",
    },
    {
        text: "How to Guides",
        url: "https://www.gatsbyjs.com/docs/how-to/",
        description:
        "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
        color: "#1099A8",
    },
    {
        text: "Reference Guides",
        url: "https://www.gatsbyjs.com/docs/reference/",
        description:
        "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
        color: "#BC027F",
    },
    {
        text: "Conceptual Guides",
        url: "https://www.gatsbyjs.com/docs/conceptual/",
        description:
        "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
        color: "#0D96F2",
    },
    {
        text: "Plugin Library",
        url: "https://www.gatsbyjs.com/plugins",
        description:
        "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
        color: "#8EB814",
    },
    {
        text: "Build and Host",
        url: "https://www.gatsbyjs.com/cloud",
        badge: true,
        description:
        "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
        color: "#663399",
    },
]

const cstyles = {
    fontSmall: {
        fontSize: "0.8em" 
    },
    navlink: {
        color: 'white',
        backgroundColor: 'black',
        padding: '0.2em',
        margin: '0.2em',
        verticalAlign: 'top',
        display: 'inline-block'
    }
}



// markup
const IndexPage = () => {

    return (
        <div class="container">
        

        {/* class bg-light, navbar, sticky-top */ }
        <nav class="bg-light navbar sticky-top d-flex flex-row">
            <div class="p-2"><Badge color="secondary" badgeContent={0}><Code /></Badge></div>
        <div class="p-2">
            <a class="nav-item" style={cstyles.navlink} href="/aboutme">aboutme</a> 
            <a class="nav-item" style={cstyles.navlink} href="/design_patterns">design&nbsp;patterns</a> 
            <a class="nav-item" style={cstyles.navlink} href="/math">math</a> 
            <a class="nav-item" style={cstyles.navlink} href="/articles">articles</a> 
            <a class="nav-item" style={cstyles.navlink} href="/portfolio">portfolio</a> 
        {/* <a class="nav-item" style={cstyles.navlink} href="/photography">photography</a> */}
        </div>
        </nav>
        <hr />
        <h1><b>Hello, I'm Jonathan Paek</b></h1>
        <div>
            <Chip label="Software Engineer">Software Engineer</Chip>
        </div>


        <div>
        <p>&nbsp;</p>
        <p>Current interests in Computer Vision, ML, and AI</p>
        <p>A new site design in progress.  If curious you can <a href='https://0edccae2.site-jonathanpaek.pages.dev/'>preview</a> a development branch</p>

        {/* devicon.dev to see list of icons */}

        {/* List of icons examples
        <h5>Software</h5>
        <div style={{fontSize: 40}} class="devicon-size">
            <i class="devicon-amazonwebservices-plain-wordmark colored"></i>
            <i class="devicon-android-plain"></i>
            <i class="devicon-angularjs-plain colored"></i>
            <i class="devicon-apache-line-wordmark colored"></i>
            <i class="devicon-arduino-plain-wordmark colored"></i>
            <i class="devicon-apple-original colored"></i>
            <i class="devicon-bash-plain colored"></i>
            <i class="devicon-bitbucket-original-wordmark colored"></i>
            <i class="devicon-bootstrap-plain colored"></i>
            <i class="devicon-circleci-plain-wordmark colored"></i>
            <i class="devicon-confluence-original-wordmark colored"></i>
            <i class="devicon-cplusplus-line colored"></i>
        </div>
        */}

        { /*
        <div class="d-flex flex-row">
            <div class="p-2"><Badge color="secondary" badgeContent={0}><Code /></Badge></div>
            <div class="p-2"><a href="/aboutme">/aboutme (new)</a> | <a href="/portfolio">/portfolio</a> | <a href="/articles">/articles</a> | <a href="/photography">/photography</a></div>
        </div>
        */ }


        { /* Sample badge icon */ }
        { /* <div class="p-2"><Badge color="secondary" badgeContent={0}><Coffee /></Badge></div> */ }


        { /* BLOCK REMOVE TABLE
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
        <TableRow>
        <TableCell>Projects</TableCell>
        <TableCell align="right">Description</TableCell>
        <TableCell align="right">Status</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row) => (
            <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell component="th" scope="row"><a href={row.route}>{row.route}</a></TableCell>
            <TableCell align="right">{row.description}</TableCell>
            <TableCell align="right">{row.status}</TableCell>
            </TableRow>
        ))}
        </TableBody>

        </Table>
        </TableContainer>
        <hr />

        */ }

        {/* PENDING REMOVE 
        <h3>Why a new personal website - my new journey!</h3>

        <p>First, this is long overdue.  I miss something about the "old web".  My plans here are just to have fun and enjoy aspets of writing, creating, and discovering.  I would enjoy it to know you have bookmark me and support me simply via readership along this journey together!  I will get that RSS and newsletter up soon.</p>

        <h3>About Site Infrastructure</h3>
        <p>A quick note on build process, I am using some custom pre/post build scripts on top of a few static-generators I am exploring.  Deployment is automated and goes straight from development to production for each new commit into the main branch and content is hosted on cloudflare.  This site is also proxied through an EC2 instance allowing me to route into any built apps I made proxied via nginx. I have been tinkering here a bit with auto deployments with AWS Fargate as well and plan to integrate any future services using this infrastructure.</p>
        <p> This site also makes use of <code>graphql</code> and <code>react</code> and <code>markdown</code> and <code>latex</code> which I learned this year to facilitate writing equations into my markdown files, and also allow for any heavy customization of any pages as needed powered by react and graphql for data organization. </p>
        <p><a href="/build_history">/build_history</a> (planned for future).  </p>

        --- PENDING REMOVE */ }

        {/* PENDING REMOVE

        <h3>Recent Books Read</h3>
        <List>
        <ListItem>
        <ListItemAvatar>
        <Avatar>
            <MenuBook/>
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary    ="Possible Minds: 25 Ways of Looking at AI" 
                      secondary  ="John Brockman" />
        </ListItem>
        <ListItem>
        <ListItemAvatar>
        <Avatar>
            <MenuBook/>
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary    ="The Book of Why" 
                      secondary  ="Judea Pearl" />
        </ListItem>
        <ListItem>
        <ListItemAvatar>
        <Avatar>
            <MenuBook/>
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary    ="You Look Like A Thing and I Love You" 
                      secondary  ="Janelle Shane" />
        </ListItem>
        <ListItem>
        <ListItemAvatar>
        <Avatar>
            <MenuBook/>
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary    ="What Hedgefunds Really Do" 
                      secondary  ="Phillip J. Romero" />
        </ListItem>
        </List>

        <p>The first 3 above are related to topics on artificial intelligence, causation, and machine learning.  The last book was about finance and during class we built a stock/market portfolio management tool incorporating some machine learning techniques. </p>

        */}

        <hr />

        <p>Site Technologies: ReactJS, GraphQL, Markdown, Cloudflare, Let's Encrypt</p>
        <p>Mini Projects are hosted on my subdomains usually with AWS, Nginx, Docker, Java, Javascript/Express</p>
        <div style={{fontSize: 20}} class="devicon-size">
            <i class="devicon-react-original"></i>
            <i class="devicon-graphql-plain"></i> 
            <i class="devicon-markdown-original"></i> 
        </div>
          <div class="text-center text-dark pt-2">
            © 2023 
          </div>

        </div>

    </div>
)
}


export default IndexPage
